<template>
  <div class="container">
    <div class="no-access-wrapper">
      <NoAvailableContent
        :header="$t('errorMessages.youDoNotHaveAccessToThisProgram')"
        :title="$t('errorMessages.toGetAccessContactTheProgramAdministrator')"
      >
        <AddButton
          class="home-button"
          :title="$t('links.studyRoom')"
          @click="$router.push('/dashboard')"
        />
      </NoAvailableContent>
    </div>
  </div>
</template>

<script>
import NoAvailableContent from '@/components/NoAvailableContent.vue';
import AddButton from '@/components/Buttons/AddButton.vue';

export default {
  components: {
    AddButton,
    NoAvailableContent,
  },
};
</script>
